:root {
  --purple: rgb(42, 29, 163);
  --gray: rgb(109, 111, 115);
  --lightgray: #2d3038;
  --darkgray: #1e1e1e;
}
/* 자동완성 선택시 배경 흰색으로 바뀌는 것 방지 */
input:-webkit-autofill {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

/* 스크롤바 CSS */
::-webkit-scrollbar {
  width: 15px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--gray);
  border-radius: 7px;

  /* 스크롤바 공간에 padding을 주는 꼼수 */
  background-clip: padding-box;
  border: 5px solid transparent;
}
::-webkit-scrollbar-track {
  background-color: var(--lightgray);
}

body {
  color: white;
}

@font-face {
  font-family: 'Inter-Light';
  src: url('/public/font/Inter-Light.ttf');
  /* 폰트 파일 경로는 절대 경로 또는 상대 경로로 지정합니다. */
}

@font-face {
  font-family: 'Inter-SemiBold';
  src: url('/public/font/Inter-SemiBold.ttf');
  /* 폰트 파일 경로는 절대 경로 또는 상대 경로로 지정합니다. */
}

.font-semibold {
  font-family: 'Inter-SemiBold';
}
.font-light {
  font-family: 'Inter-Light';
}
