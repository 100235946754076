@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* @apply를 사용하여 기존 유틸리티 클래스를 사용자 지정 CSS에 인라인화합니다. */

  .column-box {
    @apply flex flex-col;
  }
  .row-box {
    @apply flex;
  }
  .flex-center {
    @apply flex items-center justify-center;
  }
  .between-center {
    @apply flex items-center justify-between;
  }

  /* 여기다가 쓸지 아토믹에다가 쓸지 고민해봐야함 */
  .text-banner {
    @apply text-lg font-medium text-white;
  }
  .text-main {
    @apply text-base font-medium text-white;
  }
  .text-sub {
    @apply text-sm font-normal text-white;
  }
  .text-error {
    @apply text-sm font-normal text-red-500;
  }
}
